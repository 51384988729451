import * as R from 'ramda'
import * as React from 'react'

import Cookies from 'universal-cookie'
import PropTypes from 'prop-types'
import stringify from 'fast-stringify'

const CookiesContext = React.createContext()

export function Provider(props) {
  // Version is used to alter context value and propagate updates to children.
  // We could use `universal-cookie`’s event listener for changes but it would
  // not work server-side due to `useEffect` being no-op.
  const [version, setVersion] = React.useState(0)

  const getCookie = React.useCallback(
    (name) => {
      return props.cookies.get(name)
    },
    [props.cookies]
  )

  const removeCookie = React.useCallback(
    (name, value, options) => {
      const prevValue = props.cookies.get(name)

      if (prevValue == null) {
        return
      }

      props.cookies.remove(name, value, options)
      setVersion(R.inc)
    },
    [props.cookies, setVersion]
  )

  const setCookie = React.useCallback(
    (name, value, options) => {
      const prevValue = props.cookies.get(name)

      if (prevValue === value || prevValue === stringify(value)) {
        return
      }

      props.cookies.set(name, value, options)
      setVersion(R.inc)
    },
    [props.cookies, setVersion]
  )

  const value = React.useMemo(
    () => ({
      get: getCookie,
      remove: removeCookie,
      set: setCookie,
      version,
    }),
    [getCookie, removeCookie, setCookie, version]
  )

  // Workaround to keep cookies state updated. Cookie updates made via Redux
  // Effects do not propagate to `universal-cookie` we poll cookie jar to see
  // if any changes were made since last check.
  // TODO: Remove when cookies are fully managed by this provider
  React.useEffect(() => {
    let prevCookie = document.cookie
    const id = window.setInterval(() => {
      if (prevCookie !== document.cookie) {
        prevCookie = document.cookie
        setVersion(R.inc)
      }
    }, 1000)
    return () => window.clearInterval(id)
  }, [props.cookies, setVersion])

  return (
    <CookiesContext.Provider value={value}>
      {props.children}
    </CookiesContext.Provider>
  )
}

Provider.defaultProps = {
  cookies: new Cookies(),
}

Provider.displayName = 'Cookies.Provider'

Provider.propTypes = {
  children: PropTypes.node.isRequired,
  cookies: PropTypes.object.isRequired,
}

export function useContext() {
  return React.useContext(CookiesContext)
}

export function useCookie(name) {
  const cookies = useContext()

  const value = cookies.get(name)
  const setValue = React.useCallback(
    (value, options) => {
      if (value == null) {
        cookies.remove(name, options)
      } else {
        cookies.set(name, value, options)
      }
    },
    [cookies, name]
  )

  return [value, setValue]
}
