import * as Agent from './agent'
import * as Analytics from '@rushplay/analytics'
import * as Api from '@rushplay/api-client'
import * as Configuration from './configuration'
import * as Cookies from './cookies'
import * as Gtm from '@rushplay/analytics/gtm'
import * as Netrefer from '@rushplay/analytics/netrefer'
import * as Notifications from '@rushplay/notifications'
import * as Player from './player'
import * as Processes from '@rushplay/processes'
import * as Redux from 'redux'
import * as Session from './session'
import * as Websockets from '@rushplay/websockets'

import {gtmEvents, netreferEvents} from './analytics'

import {composeWithDevTools} from 'redux-devtools-extension/developmentOnly'
import reduxEffectsFetchMiddleware from 'redux-effects-fetch'
import reduxEffectsMiddleware from 'redux-effects'
import reduxMultiMiddleware from 'redux-multi'

export function createStore(options = {}) {
  return Redux.createStore(
    Redux.combineReducers({
      analytics: Analytics.reducer,
      api: Api.reducer,
      configuration: Configuration.reducer,
      notifications: Notifications.reducer,
      player: Player.reducer,
      processes: Processes.reducer,
      session: Session.reducer,
    }),
    options.initialState,
    composeWithDevTools(
      Redux.applyMiddleware(
        reduxMultiMiddleware,
        Websockets.middleware({
          bindings: {
            [Websockets.SESSION_EXPIRED]: () => [
              Session.update(),
              Notifications.add({
                message: 'session-expired',
                level: 'info',
              }),
            ],
          },
        }),
        Agent.middleware,
        Api.middleware({
          countryCodeSelector: (state) =>
            Configuration.getCountryCode(state.configuration),
          defaultCacheFor: process.browser
            ? // CLIENT: No caching by default, needs to be set to 1 ms as 0
              // disables caching hence causes requests on hydration
              1
            : // SERVER: Roughly the average time to load page on client
              // completetly. This ensures we reuse cache from server requests,
              // but let client fetch fresh data if needed.
              30000,
          host: (state) => Configuration.getApiUrl(state.configuration),
          substateSelector: (state) => state.api,
          tokenSelector: (state) => Session.getToken(state.session),
        }),
        Cookies.middleware(options.cookies),
        reduxEffectsMiddleware,
        reduxEffectsFetchMiddleware,
        Analytics.eventQueuingMiddleware({
          events: [gtmEvents, netreferEvents],
          queueSelector: (state) => Analytics.getEventsQueue(state.analytics),
          onError: (error) => {
            if (process.browser && window.onerror) {
              window.onerror(error)
            }
          },
        }),
        Gtm.middleware(gtmEvents),
        Netrefer.middleware(netreferEvents, {
          // TODO: Get from BE (configuration)
          clientId: '8d908ecd-f205-408a-9f5a-acbb0c8c11c9',
          endpoint: 'http://tracking.netrefer.com/Tracking.svc/Track',
        })
      )
    )
  )
}
