import * as Configuration from '../configuration'
import * as Constants from '../constants'
import * as Cookies from '../cookies'
import * as React from 'react'
import * as ReactRedux from 'react-redux'

export function useClientTypeObserver() {
  const dispatch = ReactRedux.useDispatch()

  React.useEffect(() => {
    const isTouchDevice =
      'ontouchstart' in window ||
      navigator.MaxTouchPoints > 0 ||
      navigator.msMaxTouchPoints > 0

    const clientType = isTouchDevice
      ? Constants.ClientType.MOBILE_BROWSER
      : Constants.ClientType.BROWSER

    dispatch(
      Cookies.set(Constants.CookieKeys.CLIENT_TYPE, clientType, {
        maxAge: 24 * 60 * 60 * 1000,
        httpOnly: false,
        path: '/',
      }),
      Configuration.updateClientType(clientType)
    )
  }, [dispatch])
}
