import * as Analytics from '@rushplay/analytics'
import * as Api from '@rushplay/api-client'
import * as Constants from './constants'
import * as Cookies from './cookies'
import * as Notifications from '@rushplay/notifications'
import * as Processes from '@rushplay/processes'
import * as R from 'ramda'
import * as ReduxEffects from 'redux-effects'
import * as Reselect from 'reselect'
import * as Websockets from '@rushplay/websockets'

const SESSION_UPDATED = 'session/UPDATED'

const INITIAL_STATE = {
  createdAt: null,
  token: null,
}

export function update(token = null, createdAt = null) {
  return ReduxEffects.bind(
    Cookies.set(Constants.CookieKeys.TOKEN, token, {
      maxAge: 24 * 60 * 60 * 1000,
      httpOnly: false,
      path: '/',
    }),
    () => ({
      type: SESSION_UPDATED,
      payload: {
        createdAt,
        token,
      },
    })
  )
}

export function login(data, onSuccess) {
  return Api.login(data.email, data.password, data.clientType, {
    success: (response) => {
      return [
        onSuccess(),
        Analytics.authenticate(),
        Processes.stop('LOGIN_LOADING'),
        update(response.value.token, response.value.loggedInAt),
      ]
    },
    failure: (error) => {
      const errors = R.pathOr({}, ['value', 'errors', 'base'], error)
      const invalidCredentials = R.find(
        R.pathEq(['error'], 'Invalid Credentials'),
        errors
      )

      if (invalidCredentials) {
        return [
          Processes.stop('LOGIN_LOADING'),
          Notifications.add({
            message: 'errors.login.invalid-credentials',
            level: 'error',
          }),
        ]
      }

      return [
        Processes.stop('LOGIN_LOADING'),
        Notifications.add({
          message: 'errors.general.unknown',
          level: 'error',
        }),
      ]
    },
    version: 2,
  })
}

export function logout() {
  return Api.logout({
    success: () => [{type: Websockets.SESSION_EXPIRED}, update()],
    version: 1,
  })
}

export function fetch(token) {
  return Api.fetchSession({
    token,
    success: (response) =>
      update(response.value.token, response.value.loggedInAt),
    failure: () => update(),
    version: 1,
  })
}

/**
 * This action checks if the session is still alive or not.
 * If it's dead we update state.
 * on success we do nothing, as updating would trigger uneccesary re-renders and re-fetches.
 */
export function keepAlive(token) {
  return Api.fetchSession({
    token,
    failure: update(),
    version: 2,
  })
}

export function reducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case Websockets.SESSION_EXPIRED:
    case SESSION_UPDATED: {
      const createdAt = R.path(['payload', 'createdAt'], action)
      const token = R.path(['payload', 'token'], action)

      if (token) {
        return {createdAt, token}
      }

      return INITIAL_STATE
    }

    default: {
      return state
    }
  }
}

export function getToken(state) {
  return R.pathOr(null, ['token'], state)
}

export function getCreatedAt(state) {
  return R.pathOr(null, ['createdAt'], state)
}

export const isAuthenticated = Reselect.createSelector([getToken], (token) =>
  Boolean(token)
)
