import * as Player from '../player'
import * as React from 'react'
import * as ReactRedux from 'react-redux'
import * as Session from '../session'

import {analyticsRegistration} from '../analytics'

/**
 * Maintain player-dependent state up-to-date
 */
export function useSessionListener() {
  const dispatch = ReactRedux.useDispatch()
  const token = ReactRedux.useSelector((state) =>
    Session.getToken(state.session)
  )

  // Fetch data on token change
  React.useEffect(() => {
    if (token) {
      dispatch([analyticsRegistration(), Player.fetchPlayerInfo()])
    }
  }, [dispatch, token])

  React.useEffect(() => {
    function handleKeepAlive() {
      if (!document.hidden) {
        dispatch(Session.keepAlive(token))
      }
    }

    if (token) {
      // Setup listener for tab-change and pauses. (example: closing lid of laptop)
      document.addEventListener('visibilitychange', handleKeepAlive)

      return () => {
        document.removeEventListener('visibilitychange', handleKeepAlive)
      }
    }
  }, [dispatch, token])
}
