import * as Analytics from '@rushplay/analytics'
import * as React from 'react'
import * as ReactRedux from 'react-redux'
import * as ReactRouter from 'react-router-dom'

export function usePageview() {
  const initialRender = React.useRef(true)

  const dispatch = ReactRedux.useDispatch()
  const location = ReactRouter.useLocation()

  React.useEffect(() => {
    if (initialRender.current) {
      // Initial render pageview is tracked by Google Analytics automatically,
      // therefore we skip dispatch for it to avoid discrepancies.
      initialRender.current = false
    } else {
      dispatch(
        Analytics.pageview({
          path: `${location.pathname}${location.search}${location.hash}`,
          title: document.title,
        })
      )
    }
  }, [
    dispatch,
    initialRender,
    location.pathname,
    location.hash,
    location.search,
  ])
}
