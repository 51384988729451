import * as Configuration from '../configuration'
import * as R from 'ramda'
import * as ReactRedux from 'react-redux'

import Url from 'url'
import {toImageUrl} from '../image'

/**
 * The hook to return a processed Imgproxy URL
 * @param {?string} url Original image URL
 * @param {?Array|?Object} transforms Desired parameters to process the image.
 * @param {?string} imgProxyBucket which bucket image is located in
 * @return {Array} Processed Imgproxy URLs.
 * If an object is passed in transforms, will return an array with 1 resulting
 * URL, if an array is passed in transforms, will return an array of URLs.
 */
export function useImgproxyUrl(url, transforms, imgProxyBucket = 'default') {
  const defaultImgproxyUrl = ReactRedux.useSelector((state) =>
    Configuration.getImgproxyUrl(state.configuration)
  )
  const babelFiskImgproxyUrl = ReactRedux.useSelector((state) =>
    Configuration.getBabelFiskImgProxyUrl(state.configuration)
  )
  const imgproxyUrl =
    imgProxyBucket === 'babel-fisk' ? babelFiskImgproxyUrl : defaultImgproxyUrl
  const dpr = Configuration.getDevicePixleRatio()
  const hasWebpSupport = ReactRedux.useSelector((state) =>
    Configuration.getHasWebpSupport(state.configuration)
  )

  if (!url) {
    return []
  }

  return Array.isArray(transforms)
    ? R.map(
        (transform) =>
          toImageUrl(imgproxyUrl, url, {
            ...transform,
            dpr,
            extension: hasWebpSupport
              ? 'webp'
              : R.path(['extension'], transform),
          }),
        transforms
      )
    : [
        toImageUrl(imgproxyUrl, url, {
          ...transforms,
          dpr,
          extension: hasWebpSupport
            ? 'webp'
            : R.path(['extension'], transforms),
        }),
      ]
}

/**
 * The hook to return a processed Imgproxy URL for a translation image
 * @param {?string} url Original image URL (translation image)
 * @param {?Array|?Object} transforms Desired parameters to process the image.
 * @return {Array} Processed Imgproxy URLs.
 * If an object is passed in transforms, will return an array with 1 resulting
 * URL, if an array is passed in transforms, will return an array of URLs.
 */
export function useTranslationImgproxyUrl(url, transforms, imgProxyBucket) {
  const {pathname} = Url.parse(url)

  return useImgproxyUrl(pathname, transforms, imgProxyBucket)
}
