import * as React from 'react'
import * as ReactRouterDom from 'react-router-dom'

export function useNavigationScrollTop() {
  const location = ReactRouterDom.useLocation()

  React.useEffect(() => {
    window.scrollTo(0, 0)
  }, [location.pathname])
}
