import * as Analytics from '@rushplay/analytics'
import * as Api from '@rushplay/api-client'
import * as Player from './player'
import * as R from 'ramda'

function present(values) {
  return R.none(R.isNil, values)
}

export const gtmEvents = {
  [Analytics.AUTHENTICATED]: (action, prevState, nextState) => {
    const uid = Player.getUsername(nextState.player)
    const affiliateid = Player.getAffiliateId(nextState.player)

    if (R.not(present([uid]))) {
      return null
    }

    return {
      event: 'login',
      user: {
        uid,
        affiliateid,
      },
    }
  },

  [Analytics.PAGE_VIEWED]: (action) => ({
    event: 'pageview',
    page: {
      path: action.payload.path,
      title: action.payload.title,
    },
  }),

  [Analytics.REGISTERED]: (action, prevState, nextState) => {
    const uid = Player.getUsername(nextState.player)
    const affiliateid = Player.getAffiliateId(nextState.player)

    if (R.not(present([uid]))) {
      return null
    }

    return {
      event: 'nrc',
      user: {
        uid,
        affiliateid,
      },
    }
  },
}

export const netreferEvents = {
  [Analytics.REGISTERED]: (action, prevState, nextState) => {
    const customerID = Player.getUsername(nextState.player)

    if (R.not(present([customerID]))) {
      return null
    }

    return {
      actionName: 'SignUp',
      // TODO; get actual id
      brand: 3,
      btag: Player.getBtag(nextState.player),
      customFields: {
        clickID: Player.getAffiliateClickId(nextState.player),
        customerID,
      },
      method: 'both',
    }
  },
}

export function analyticsRegistration() {
  return Api.fetchNotificationsByKind('analytics', {
    success: (res) => {
      const signUpNotification = R.find(R.propEq('event', 'signup'), res.value)

      if (signUpNotification) {
        return Api.deleteNotification(signUpNotification.id, {
          success: Analytics.register,
          version: 1,
        })
      }
    },
    version: 1,
  })
}
