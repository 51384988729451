import * as React from 'react'
import * as Urql from 'urql'

function usePreviousValue(value) {
  const ref = React.useRef(value)
  React.useEffect(() => {
    ref.current = value
  })
  return ref.current
}

/**
 * Use query with pagination support
 * @param {Object} options
 * @param {function(prev: T, next: T): T} options.resolver
 *   Function to merge previous data with next data correctly
 * @template T
 */
export function usePaginatedQuery({query, resolver, variables}) {
  const [offset, setOffset] = React.useState(0 + variables.offset)
  const [data, setData] = React.useState()

  const [response] = Urql.useQuery({
    query,
    variables: {
      ...variables,
      offset,
    },
  })

  const prevFetching = usePreviousValue(response.fetching)

  React.useEffect(() => {
    if (
      (data == null || prevFetching === true) &&
      response.fetching === false &&
      !response.error
    ) {
      setData((prevData) => resolver(prevData, response.data))
    }
  }, [prevFetching, response.fetching, response.data, response.error])

  const fetchMore = React.useCallback(
    () => setOffset((s) => s + variables.limit),
    [variables.limit]
  )

  return [
    {
      data: data || response.data,
      error: response.error,
      fetching: response.fetching,
    },
    fetchMore,
  ]
}
