import * as R from 'ramda'
import * as ReactRouterDom from 'react-router-dom'

import querystring from 'querystring'

/**
 * Only updates specified queries
 * @param {Object} location
 * @param {Object} nextQuery
 * @returns {string} querystring with updated values
 */
function getSafeUpdateQuery(location, nextQuery) {
  const prevQuery = querystring.parse(R.drop(1, location.search))

  return querystring.stringify(R.reject(R.isNil, {...prevQuery, ...nextQuery}))
}

export function useSafeUpdateQuery(nextQuery) {
  const location = ReactRouterDom.useLocation()

  return getSafeUpdateQuery(location, nextQuery)
}
