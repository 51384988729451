import * as Api from '@rushplay/api-client'
import * as Websockets from '@rushplay/websockets'

export const CLEARED = '@rushplay/player/CLEARED'
export const INIT = '@rushplay/player/INIT'

export function playerInfoInit(response) {
  if (response instanceof Error) {
    return {
      type: INIT,
      error: true,
      payload: response,
    }
  }

  return {
    type: INIT,
    payload: {
      affiliateClickId: response.affiliateClickId || '',
      affiliateId: response.affiliateId || null,
      affiliateTag: response.affiliateTag || null,
      countryCode: response.countryCode || '',
      email: response.email || '',
      language: response.language || '',
      username: response.username || '',
    },
  }
}

export function fetchPlayerInfo() {
  // TODO: replace 'fetchSession' with 'fetchPlayerInfo' when 'fetchPlayerInfo' contains player data
  // return api.fetchPlayerInfo({
  //   version: 2,
  //   success: res => playerInfoInit(res.value.result),
  //   failure: res =>
  //     playerInfoInit(
  //       new Error(`errors.${res.value.message || 'general.unknown'}`)
  //     ),
  // })
  return Api.fetchSession({
    version: 1,
    success: (sessionRes) => [
      Api.fetchPlayerInfo({
        version: 2,
        success: (res) => {
          const playerInfo = res.value.result
          const player = sessionRes.value.player

          return [
            playerInfoInit({
              ...player.account,
              ...player.address,
              ...player,
              ...playerInfo,
            }),
          ]
        },
        failure: (res) => {
          return playerInfoInit(
            new Error(`errors.${res.value.message || 'general.unknown'}`)
          )
        },
      }),
    ],
    failure: (res) =>
      playerInfoInit(
        new Error(`errors.${res.value.message || 'general.unknown'}`)
      ),
  })
}

export function clearPlayerInfo() {
  return {
    type: CLEARED,
  }
}

export function reducer(state = {}, action) {
  if (action.error) {
    return state
  }

  switch (action.type) {
    case INIT: {
      return {
        ...state,
        ...action.payload,
      }
    }

    case Websockets.SESSION_EXPIRED:
    case CLEARED:
      return {}

    default:
      return state
  }
}

export function getAffiliateClickId(state) {
  return state.affiliateClickId
}

export function getAffiliateId(state) {
  return state.affiliateId
}

function getAffiliateTag(state) {
  return state.affiliateTag
}

export function getBtag(state) {
  const affiliateId = getAffiliateId(state)
  const affiliateTag = getAffiliateTag(state)

  if (!affiliateId || !affiliateTag) {
    return null
  }

  return `${affiliateId}_${affiliateTag}`
}

export function getEmail(state) {
  return state.email
}

export function getUsername(state) {
  return state.username
}
