import * as Configuration from '../configuration'
import * as Player from '../player'
import * as React from 'react'
import * as ReactRedux from 'react-redux'
import * as Session from '../session'
import * as Websockets from '@rushplay/websockets'

export function useWebsocketListener() {
  const dispatch = ReactRedux.useDispatch()
  const sessionToken = ReactRedux.useSelector((state) =>
    Session.getToken(state.session)
  )
  const username = ReactRedux.useSelector((state) =>
    Player.getUsername(state.player)
  )
  const {
    authEndpoint,
    cluster,
    enabledTransports,
    encrypted,
    key,
  } = ReactRedux.useSelector((state) =>
    Configuration.getPusher(state.configuration)
  )

  // Init and re-init on token change
  React.useEffect(() => {
    if (key) {
      dispatch(
        Websockets.init(key, {
          auth: {
            params: {
              username,
              sessionToken,
            },
          },
          cluster: cluster,
          authEndpoint: authEndpoint,
          encrypted: encrypted,
          enabledTransports: enabledTransports,
        })
      )
    }
  }, [
    authEndpoint,
    cluster,
    dispatch,
    enabledTransports,
    encrypted,
    key,
    sessionToken,
    username,
  ])
}
